<template>
<div>
    <b-card>
        <b-card-header>
            <h4>Billing Summary</h4>
            <h5>Invoice Number: {{ invoiceNo }} </h5>
        </b-card-header>
        <b-card-text class="px-2">
        <div
        style="overflow-x: auto;"
        class="mb-2"
        >
            <b-table
                show-empty
                empty-text="Sorry! No Data Found"
                :items="items"
                :fields="fields"
                responsive="sm"
                :busy="isLoading"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle" />
                        <strong>&nbsp; Loading...</strong>
                    </div>
                </template>
                <template>
                    <div
                    v-if="items.length === 0"
                    class="row"
                    >
                        <div class="col col-md-12">
                            <p>No Data Found</p>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
        </b-card-text>
    </b-card>
    <b-card
        class="mb-2"
        v-if="status==='complete' || status==='approved'"
    >
        <b-card-header>
            <div>
                <h4>For Office Use Only</h4>
            </div>
        </b-card-header>
        <!-- <b-card v-if="emptyCheck">
            <b-row>
                <b-col>
                    <p>Sorry! No Data Found</p>
                </b-col>
            </b-row>
        </b-card> -->
        <b-card-text class="px-2">
            <b-row>
                <b-col
                cols="4"
                class="font-weight-bold"
                >
                Approved By
                </b-col>
                <b-col cols="8">
                    <p>{{ managerName }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col
                cols="4"
                class="font-weight-bold"
                >
                Invoiced From
                </b-col>
                <b-col cols="8">
                    <p>{{ managerBranch }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col
                cols="4"
                class="font-weight-bold"
                >
                Approval Date
                </b-col>
                <b-col cols="8">
                    <p>{{ approvalDateTime }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col
                cols="4"
                class="font-weight-bold"
                >
                Customer To Bill To
                </b-col>
                <b-col
                md="4"
                class="invoice-block"
                >
                    <b-form-group
                        v-if="officialEdit"
                        label="Customer"
                    >
                        <v-select
                            id="id"
                            label="client_name"
                            :value="clientName"
                            :options="clientList"
                            @search="searchClientData"
                            @input="selectedClient"
                        />
                    </b-form-group>
                    <p v-else>
                        {{ clientName }}</p>
                </b-col>
                <b-col md="2">
                    <div class="float-right">
                        <b-button
                            v-if="checkRoleBill('jobs-editcustomerbill') && officialEdit && checkCompleted"
                            class="feather-icon-btn"
                            size="sm"
                            variant="outline-primary"
                            @click="submitInvoiceData"
                        >
                            <feather-icon class="feather-icon-style" icon="CheckIcon" />
                        </b-button>
                        <b-button
                            v-if="checkRoleBill('jobs-editcustomerbill') && officialEdit && checkCompleted"
                            class="ml-1"
                            size="sm"
                            variant="outline-primary"
                            @click="passStatus('officialEdit', false)"
                        >
                            <feather-icon icon="XIcon" />
                        </b-button>
                        <b-button
                            v-else-if="checkRoleBill('jobs-editcustomerbill') && checkCompleted"
                            size="sm"
                            variant="outline-primary"
                            @click="passStatus('officialEdit', true)"
                        >
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</div>
</template>

<script>
import {
    BTable, BCard, BCardText, BCardHeader, BSpinner, BRow, BCol, BButton, BFormGroup,
    } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';

export default {
    components: {
        BTable, BCard, BCardText, BCardHeader, BSpinner, BRow, BCol, BButton, BFormGroup, vSelect,
    },
    props: {
        roleIdForBlock: {
            type: Boolean,
            required: true,
            default: true,
        },
        profileId: {
            type: Number,
            default: 0,
        },
        roleIdPriority: {
            type: Boolean,
            required: true,
            default: true,
        },
        checkCompleted: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: '',
        },
    },
    watch: {
        status() {
            this.getManagerData()
            this.getManagerCustomerData()
        },
    },
    data() {
        return {
            id: 0,
            items: [],
            fields: [],
            isLoading: false,
            invoiceNo: 'Not created yet',
            officialEdit: false,
            managerBranch: 'Not available',
            managerName: 'Not available',
            clientId: 0,
            clientList: [],
            approvalDateTime: 'Not available',
            emptyCheck: false,
            clientName: '',
            clientLimit: 1000,
        };
    },
    created() {
        console.log(this.$route)
        this.id = this.$route.params.id;
        this.fetchData();
        this.getManagerData()
        this.getManagerCustomerData()
    },
    methods: {
        fetchData() {
            this.isLoading = true;
            this.$axios.post(`/jobs/send_invoice/${this.id}`, { job_id: this.id })
                .then(res => {
                        this.items = res.data.product_details
                        this.invoiceNo = res.data.invoice_number
                        if (this.items.length > 0) {
                            this.fields = Object.keys(this.items[0])
                            // this.fields.splice(this.fields.indexOf('id'), 1)
                        }
                        this.isLoading = false;
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            props: 'Oops! Somthing went wrong',
                            icon: 'AllertCircleIcon',
                            variant: 'success',
                        });
                        console.error(err)
                    });
        },
        passStatus(data, val) {
            this.officialEdit = val
        },
        officialDataUpdate() {
        },
        getManagerData() {
            this.$axios.post(`jobs/manager_apporval_data`,
                {
                    job_id: this.id,
                })
                .then(res => {
                    if (res.data.status && res.data.result) {
                        console.log(res.data.result)
                        this.managerBranch = res.data.result.branch_name ?? 'Not set up'
                        this.managerName = res.data.result.manager_name ?? 'Not set up'
                        this.clientId = res.data.result.client_id
                        this.approvalDateTime = this.$moment(res.data.result.approval_date).format('DD/MM/YY hh:mma')
                    } else {
                        this.emptyCheck = true
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        submitInvoiceData() {
            this.$axios.post(`jobs/create_invoice_client_data`,
                {
                    job_id: this.id,
                    client_id: this.clientId,
                })
                .then(res => {
                    if (res.data.status) {
                        console.log(res.data.result)
                        this.officialEdit = !this.officialEdit
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Record Updated',
                                icon: 'CheckIcon',
                                variant: 'primary',
                            },
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                })
        },
        getManagerCustomerData() {
            this.$axios.post('jobs/manager_customer_data',
                {
                    job_id: this.id,
                })
                .then(res => {
                    if (res.data.status && res.data.result) {
                        this.clientList = res.data.result.filter((item, inx) => inx < this.clientLimit)
                        this.storedClientList = res.data.result
                        const clientData = res.data.result.find(itm => itm.id.toString() === this.clientId.toString())
                        this.clientName = clientData ? clientData.client_name : 'Not set up'
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        selectedClient(val) {
            if (val) {
                this.clientId = val.id
                this.clientName = val.client_name
            }
        },
        searchClientData(val) {
            if (val.length > 0) {
                this.clientList = this.storedClientList.filter((item, inx) => item.client_name.toUpperCase().search(val.toUpperCase()) > -1)
            } else {
                this.clientList = this.storedClientList.filter((item, inx) => inx < this.clientLimit)
            }
        },
        checkRoleBill(val) {
            return this.$store.state.auth.user.permissions.indexOf(val) > -1
        },
    },
}
</script>

<style scoped>
    .row {
        margin-bottom: 0.5rem;
    }
    .feather-icon-style {
        color: #fff;
    }
    .feather-icon-btn {
        border: 1px solid #fff !important;
        background-color: #008000 !important;
    }
    .alert.alert-dismissible.alert-secondary {
        position: relative;
        padding: 0.75rem 4rem 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
    .col-8.invoice-block {
        display: flex;
        justify-content: space-between;
    }
</style>
