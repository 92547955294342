<!-- eslint-disable vue/no-unused-vars -->
<template>
    <div>
        <!-- Modals -->
        <b-modal
            id="generel-services-modal"
            title="Select Services"
            ok-only
            no-close-on-backdrop
            @ok="addGeneralService"
        >
            <v-select
                v-model="generalServiceId"
                label="description"
                :reduce="item => item.id"
                :options="jobGeneralServices"
            />
        </b-modal>

        <b-modal
            id="tyre-services-modal"
            title="Select Services"
            ok-only
            no-close-on-backdrop
            @ok="addTyreService"
        >
            <v-select
                v-model="tempTyreServiceId"
                label="description"
                :reduce="item => item.id"
                :options="allServices"
            />
        </b-modal>

        <b-modal
            id="products-modal"
            title="Select Products"
            no-close-on-backdrop
            hide-footer
            @close="closeProductModal"
        >
            <b-input-group>
                <b-form-input
                    placeholder="Search Product"
                    @input="debounceInput"
                />
                <b-spinner
                v-if="options.length === 0 && productLoader "
                variant="primary"
                style="width: 1.5rem; height: 1.5rem; margin: 7px 0 0 2px;"
                label="Spinning"
                />
            </b-input-group>
            <b-list-group v-if="options">
                <b-list-group-item
                    v-for="product in options"
                    :key="product.id"
                    @click="addProduct(product)"
                >
                    {{ product.description }}
                </b-list-group-item>
            </b-list-group>
            <p
            v-if="options.length === 0 && productInputSearch.length > 2"
            >
            Sorry, no matching options.
            </p>
            <p
            v-if="options.length === 0 && productInputSearch.length < 3"
            >
            Enter at least 2 characters.
            </p>
            <!-- <v-select
                :options="options"
                label="description"
                :loading="productLoader"
                @search="debounceInput"
                @option:selected="addProduct"
            />
            <custom-autosuggest
                table="products"
                label="description"
                url="/jobs/get_products_masterlist"
                retrieve="object"
                @changed="addProduct"
            /> -->
        </b-modal>

        <b-modal
            id="images-modal"
            title="Select Image"
            ok-only
            no-close-on-backdrop
            @ok="addImage"
        >
            <b-form-file
                v-model="wheelImage"
                placeholder="Choose a file"
                accept="image/*"
                multiple
            />
            <small>You can multi-select up to 4 photos each time you add</small>
        </b-modal>

        <b-modal
            id="job-card-images-modal"
            title="Select Image"
            ok-only
            no-close-on-backdrop
            @ok="addCardImage"
        >
            <b-form-file
                v-model="jobCardImage"
                placeholder="Choose a file"
                accept="image/*"
                multiple
            />
            <small>You can multi-select up to 4 photos each time you add</small>
        </b-modal>

        <b-modal
            id="wheel-position-delete-modal"
            title="Delete Confirmation"
            @ok="resetFilter"
        >
            <p class="my-4">
                Are you sure you want to clear the current wheel position?
            </p>
        </b-modal>

        <b-modal
            id="delete_modal"
            ref="delete_modal"
            title="Delete Confirmation"
            @ok="deleteConfirm"
        >
            <p class="my-4">
                Are you sure you want to delete?
            </p>
        </b-modal>
        <b-modal
            v-if="checkCompleted && roleIdForBlock"
            v-model="virJobModal"
            size="lg"
            ok-only
            :title="`Selected Wheel Position ${selectedWheel.join(',')}`"
            @hidden="resetModal"
            @ok="virJobModal=!virJobModal"
            @close="closeModal"
            @show="openModal"
        >
            <div class="mx-2">
                <b-row class="mb-1">
                    <b-col cols="12" class="mb-1">
                        <label for="tread-depth">Tread Depth:</label>
                        <b-form-input
                            id="tread-depth"
                            v-model="selectedTyre.tread_depth"
                            type="number"
                            :state="selectedTyre.tread_depth=='' ? false : null"
                            placeholder="Tread Depth"
                            trim
                            :disabled="textboxDisability"
                        />
                        <b-form-invalid-feedback id="tread-depth">
                            Please select Tread Depth
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col cols="12" class="mb-1">
                        <div class="d-flex">
                            <p class="mr-2">
                                Action required
                            </p>
                            <b-form-checkbox
                                v-model="selectedTyre.wheel_has_issue"
                                switch
                                :disabled="textboxDisability"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" class="mb-1" v-if="selectedTyre.wheel_has_issue">
                        <b-form-group
                            :state="selectedTyre.vir_action_id ? null:false"
                        >
                        <v-select
                            v-model="selectedTyre.vir_action_id"
                            placeholder="Select Action"
                            label="name"
                            :reduce="item => item.id"
                            :options="virActions"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mb-1" v-if="selectedTyre.wheel_has_issue">
                        <label for="tread-depth">Notes:</label>
                        <b-form-textarea
                            id="note"
                            v-model="selectedTyre.note"
                            placeholder="Notes"
                            trim
                            :disabled="textboxDisability"
                        />
                        <!--<b-form-invalid-feedback id="tread-depth">
                            Please select Tread Depth
                        </b-form-invalid-feedback>-->
                    </b-col>
                    <b-col v-if="selectedTyre.wheel_has_issue">
                        <label for="vir-img">Photos:</label>
                        <b-row>
                            <b-col cols="8">
                                <b-form-file
                                    v-model="wheelImage"
                                    placeholder="Choose a file"
                                    accept="image/*"
                                    multiple
                                />
                                <small>You can multi-select up to 4 photos each time you add</small>
                            </b-col>
                            <b-col cols="4">
                                <b-button
                                    v-if="checkCompleted && roleIdForBlock"
                                    style="float: right;"
                                    variant="outline-primary"
                                    @click="addImage"
                                > Add</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!-- Cards -->
        <b-card ref="crd1">
            <b-card-header>
                <div
                    class="d-flex justify-content-between"
                    style="width:100%;"
                >
                    <!--<h4>Select a wheel position to record work completed</h4>-->
                    <div class="d-flex" v-if="jobType !== 'VIR'">
                        <p class="mr-2">
                            Allow multiple select
                        </p>
                        <b-form-checkbox
                            v-model="allowMultiple"
                            switch
                            :disabled="(selectedWheel.length > 1 && textboxDisability)"
                        />
                    </div>
                </div>
            </b-card-header>
            <div
                v-if="wheelLayout.length>0"
                class="d-flex justify-content-center"
            >
                <div style="max-width: 40rem;">
                <div
                    v-for="(row,rowKey) in wheelLayout"
                    :key="`row-${rowKey}`"
                    :class="{'d-flex mb-1': true, 'justify-content-between': true, }"
                >
                    <a
                        v-for="(wheel, wheelKey) in row"
                        :key="`wheel-${wheelKey}`"
                        :class="{
                            'btn-pos mx-1 width-3' : true,
                            'btn-outline-primary': wheel.selected,
                            'btn-outline-warning': !wheel.selected,
                            'saved': wheel.saved && jobType!=='VIR',
                            'selected': selectedWheel.includes(wheel.name),
                            'ml-4': row.length/2==wheelKey,
                            [wheelRiskColor(wheel)]: jobType==='VIR',
                        }"
                        @click="selectWheel(rowKey, wheelKey)"
                    >
                        {{ wheel.name }}
                        <span v-if="jobType==='VIR' && wheel.saved" style="font-size: 0.7rem;">{{ wheel.tread_depth }}</span>
                    </a>
                </div>
                </div>
            </div>
        </b-card>
        <b-card v-if="selectedWheel.length > 0" ref="crd2">
            <b-card-header>
                <div class="d-flex">
                    <h4>Selected Wheel Position:&nbsp;
                    <span class="text-color-primary">{{ selectedWheel.join(',') }}</span>
                    </h4>
                </div>
                <div class="d-flex justify-content-end mb-1">
                    <!--<b-button
                        v-show="roleIdForBlock"
                        variant="primary"
                        :disabled="textboxDisability"
                        @click="submitWheelPosition"
                    >
                        Save Wheel Position
                    </b-button>-->
                    <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        variant="primary"
                        @click="submitWheelPosition"
                    >
                        Save Wheel Position
                    </b-button>
                    <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        v-show="actionShow>0"
                        title="Clear Wheel Position"
                        variant="outline-danger"
                        size="sm"
                        class="ml-1 btn-icon rounded-circle"
                        @click="wheelPositionDeleteConfirm"
                    >
                        <feather-icon
                                icon="TrashIcon"
                                svg-classes="h-10 w-10"
                            />
                    </b-button>
                </div>
            </b-card-header>
            <div class="mx-2">
                <b-row class="mb-1">
                    <b-col
                        v-if="jobType==='FSD'"
                        cols="6"
                    >
                        <label for="action">Action:</label>
                        <b-form-select
                            id="action"
                            v-model="selectedTyre.service_type_ids"
                            :state="selectedTyre.service_type_ids>0 ? null : false"
                            :options="serviceTypes"
                            value-field="id"
                            text-field="name"
                            :disabled="textboxDisability || actionShow>0"
                            @change="actionSelected"
                        />
                        <b-form-invalid-feedback id="action">
                            Please select action
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col cols="6" v-if="jobType !== 'VIR'">
                        <label for="tread-depth">Tread Depth:</label>
                        <b-form-input
                            id="tread-depth"
                            v-model="selectedTyre.tread_depth"
                            type="number"
                            :state="selectedTyre.tread_depth=='' ? false : null"
                            placeholder="Tread Depth"
                            trim
                            :disabled="textboxDisability"
                        />
                        <b-form-invalid-feedback id="tread-depth">
                            Please select Tread Depth
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <template v-if="jobType==='FSD'">
                    <b-row class="mb-4">
                        <b-col
                            v-show="showRemovalReason"
                            cols="6"
                        >
                            <label for="action">Removal Reason:</label>
                            <b-form-select
                                v-model="selectedTyre.removal_reason_id"
                                :options="removalReasons"
                                value-field="id"
                                text-field="reason"
                                :disabled="textboxDisability"
                            />
                        </b-col>
                        <b-col
                            v-show="showOldTread"
                            cols="6"
                        >
                            <label for="action">Tread Depth:</label>
                            <b-form-input
                                id="old-tread-depth"
                                v-model="selectedTyre.old_tread_depth"
                                placeholder="Tread Depth"
                                trim
                                :disabled="textboxDisability"
                            />
                        </b-col>
                        <b-col
                            v-show="showWheelPosition"
                            cols="6"
                        >
                            <label for="action">Rotating To:</label>
                            <b-form-select
                                id="selected-wheel-position"
                                v-model="selectedTyre.new_vehicle_to_wheel_position_id"
                                :options="wheelPositions"
                                value-field="id"
                                text-field="name"
                                :disabled="textboxDisability || allowMultiple"
                            />
                        </b-col>
                    </b-row>
                    <template v-if="showProduct">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <h5>Tyres Fitted </h5>
                            <b-button
                                v-if="checkCompleted && roleIdForBlock"
                                variant="outline-primary"
                                :disabled="textboxDisability"
                                @click="openProductModal"
                            > Add </b-button>
                        </div>
                        <b-table
                            striped
                            head-variant="light"
                            :fields="[{key: 'description'}, {label: '',
                            key: 'action'}]"
                            :items="selectedTyre.products"
                            show-empty
                            empty-text="No data found"
                            class="mb-4"
                        >
                            <template #cell(action)="data">
                                <b-button
                                    v-if="checkCompleted && roleIdForBlock"
                                    pill
                                    variant="outline-danger"
                                    size="sm"
                                    @click="removeProduct(data)"
                                >
                                    -
                                </b-button>
                            </template>
                        </b-table>
                    </template>

                    <div
                        v-if="showServiceCharge"
                        class="d-flex justify-content-between align-items-center mb-1"
                    >
                        <h5>Service Charges</h5>
                        <div
                        v-for="(val, inx) in servBtn"
                        :key="inx"
                        >
                        <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        variant="outline-info"
                        :disabled="textboxDisability"
                        size="sm"
                        @click="wheelServAdd(val)"
                        >{{ val }}</b-button>
                        </div>
                        <b-button
                            v-if="checkCompleted && roleIdForBlock"
                            variant="outline-primary"
                            :disabled="textboxDisability"
                            @click="openTyreServiceModal"
                        > Add </b-button>
                    </div>
                    <b-table
                        v-if="showServiceCharge"
                        striped
                        head-variant="light"
                        :fields="[{key: 'description'}, {label: '',
                        key: 'action'}]"
                        :items="getTyreServices"
                        show-empty
                        empty-text="No data found"
                        class="mb-4"
                    >
                        <template #cell(action)="data">
                            <b-button
                                v-if="checkCompleted && roleIdForBlock"
                                pill
                                variant="outline-danger"
                                size="sm"
                                @click="removeTyreService(data)"
                            >
                                -
                            </b-button>
                        </template>
                    </b-table>
                </template>

                <b-card v-if="jobType === 'VIR'">
                    <b-card-header>
                        <h5>Photos</h5>
                        <span class="float-right">
                            <!--<b-button
                                v-show="roleIdForBlock"
                                variant="outline-primary"
                                :disabled="textboxDisability"
                                @click="openImagesModal"
                            > Add </b-button>-->
                            <b-button
                                v-if="checkCompleted && roleIdForBlock"
                                variant="outline-primary"
                                @click="openImagesModal"
                            > Add </b-button>
                        </span>
                    </b-card-header>
                    <b-card-text>
                        <div class="container mt-2 ml-1">
                        <CoolLightBox
                        :items="images.map(item => item.media_name)"
                        :index="index"
                        @close="index = null"
                        />
                            <div
                                v-if="!imageEdit"
                                class="carousel_block"
                                >
                                <carousel
                                    ref="carousel"
                                    :per-page="3"
                                    :pagination-enabled="false"
                                    :navigation-enabled="true"
                                    :navigation-click-target-size="4"
                                    :loop="true"
                                    :per-page-custom="[
                                        [320, 1],
                                        [768, 1],
                                        [1199, 3],
                                    ]"
                                >
                                    <slide
                                        v-for="(img, imgIndex) in images"
                                        :key="imgIndex"
                                    >
                                        <div
                                            class="card m-2"
                                            style="cursor: pointer;"
                                        >
                                            <b-button
                                                v-if="checkCompleted && roleIdForBlock"
                                                size="sm"
                                                variant="danger"
                                                class="img-close"
                                                @click="triggerDelete(img.id, true)"
                                            >X
                                            </b-button>
                                            <img
                                                :src="img.media_name"
                                                alt=""
                                                class="card-img-top"
                                                @click="index = imgIndex"
                                            >
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                            <b-form-file
                                v-else
                                v-model="image"
                                :state="Boolean(image)"
                                placeholder="Choose a file"
                                accept="image/*"
                            />
                        </div>
                    </b-card-text>
                </b-card>
            </div>
        </b-card>
        <b-card ref="crd3">
            <b-card-header>
                <h4>Job Card</h4>
            </b-card-header>
            <div class="mx-2">
                <b-row class="mb-2">
                    <b-col
                    cols="4"
                    class="font-weight-bold"
                    >
                    Odometer Reading
                    </b-col>
                    <b-col cols="8">
                    <b-form-input
                        v-model="odometerReading"
                        type="number"
                    />
                    </b-col>
                </b-row>
                <!--<template v-if="jobType==='FSD' && showServiceCharge">-->
                <template v-if="jobType==='FSD'">
                <div class="d-flex justify-content-between align-items-center mb-1">
                    <h5>Service Charges</h5>
                    <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        variant="outline-primary"
                        :disabled="textboxDisability"
                        @click="openGeneralServiceModal"
                    > Add </b-button>
                </div>
                <div
                class="d-flex justify-content-between align-items-center mb-1"
                style="flex-wrap: wrap"
                >
                    <div
                        v-for="(val, inx) in jobCardProducts"
                        :key="inx"
                        class="mb-1"
                        >
                        <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        variant="outline-info"
                        :disabled="textboxDisability"
                        size="sm"
                        @click="jobServAdd(val)"
                        >{{ inx.toUpperCase().replaceAll('_', ' ') }}</b-button>
                    </div>
                </div>
                <b-table
                    striped
                    head-variant="light"
                    :fields="[{key: 'description'}, {key: 'quantity'}, {label: '',
                    key: 'action'}]"
                    :items="generalServQntData"
                    show-empty
                    empty-text="No data found"
                    class="mb-2"
                >
                    <template #cell(quantity)="data">
                        <b-form-input
                            v-model="data.item.quantity"
                            type="number"
                            size="sm"
                        />
                    </template>
                    <template #cell(action)="data">
                        <b-button
                            v-if="checkCompleted && roleIdForBlock"
                            pill
                            variant="outline-danger"
                            size="sm"
                            @click="removeGeneralService(data)"
                        >
                            -
                        </b-button>
                    </template>
                </b-table>
                </template>

                <div class="mb-2">
                    <label for="other-service-comments">Notes  (visible to the customer)</label>
                    <b-form-textarea
                        id="other-service-comments"
                        v-model="otherServiceComment"
                        rows="3"
                        max-rows="6"
                        :disabled="textboxDisability"
                    />
                <b-card>
                    <b-card-header>
                        <h5>Photos</h5>
                        <span class="float-right">
                            <!--<b-button
                                v-show="roleIdForBlock"
                                variant="outline-primary"
                                :disabled="textboxDisability"
                                @click="openJobCardImagesModal"
                            > Add </b-button>-->
                            <b-button
                                v-if="checkCompleted && roleIdForBlock"
                                variant="outline-primary"
                                @click="openJobCardImagesModal"
                            > Add </b-button>
                        </span>
                    </b-card-header>
                    <b-card-text>
                        <div class="container mt-2 ml-1">
                        <CoolLightBox
                        :items="cardImages.map(item => item.media_name)"
                        :index="index"
                        @close="index = null"
                        />
                            <div
                                class="carousel_block"
                                >
                                <carousel
                                    ref="carousel"
                                    :per-page="3"
                                    :pagination-enabled="false"
                                    :navigation-enabled="true"
                                    :navigation-click-target-size="4"
                                    :loop="true"
                                    :per-page-custom="[
                                        [320, 1],
                                        [768, 1],
                                        [1199, 3],
                                    ]"
                                >
                                    <slide
                                        v-for="(img, imgIndex) in cardImages"
                                        :key="imgIndex"
                                    >
                                        <div
                                            class="card m-2"
                                            style="cursor: pointer;"
                                        >
                                        <b-button
                                            v-if="checkCompleted && roleIdForBlock"
                                            size="sm"
                                            variant="danger"
                                            class="img-close"
                                            @click="triggerDelete(img.id, false)"
                                        >X
                                        </b-button>
                                            <img
                                                :src="img.media_name"
                                                alt=""
                                                class="card-img-top"
                                                @click="index = imgIndex"
                                            >
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </b-card-text>
                </b-card>
                </div>
                <div class="d-flex justify-content-end mb-1">
                    <!-- <b-button
                        v-show="roleIdForBlock"
                        variant="primary"
                        :disabled="textboxDisability"
                        @click="saveJobCardDetails"
                    >
                        Save Job Card Details
                    </b-button> -->
                    <b-button
                        v-if="checkCompleted && roleIdForBlock"
                        variant="primary"
                        @click="saveJobCardDetails"
                    >
                        Save Job Card Details
                    </b-button>
                </div>
            </div>
        </b-card>
        <!-- <b-button class="float-next" size="sm" variant="outline-primary" @click="nxtBtn">
            <feather-icon icon="ChevronRightIcon" />
        </b-button>
        <b-button class="float-prv" size="sm" variant="outline-primary" @click="prvBtn">
            <feather-icon icon="ChevronLeftIcon" />
        </b-button> -->
    </div>
</template>
<script>
    import * as imageConversion from 'image-conversion';
    import {
 BCard, BCardHeader, BCardText, BFormCheckbox, BRow, BFormInput, BFormSelect, BCol, BButton, BTable, BFormTextarea, BFormFile, BFormInvalidFeedback, BListGroupItem, BListGroup, BSpinner, BInputGroup, BFormGroup,
} from "bootstrap-vue";
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import VSelect from "vue-select";
    import { Carousel, Slide } from 'vue-carousel';
    import CoolLightBox from 'vue-cool-lightbox';
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
    import _ from "lodash";
    import CustomAutosuggest from "../../common/components/CustomAutosuggest.vue"

    export default {
        components: {
            BCard, BCardHeader, BCardText, BFormCheckbox, BRow, BFormInput, BFormSelect, BCol, BButton, BTable, BFormTextarea, VSelect, Carousel, Slide, CoolLightBox, BFormFile, BFormInvalidFeedback, BListGroupItem, BListGroup, BSpinner, /* CustomAutosuggest, */ BInputGroup, BFormGroup,
        },
        props: {
            roleIdForBlock: {
                type: Boolean,
                required: true,
                default: true,
            },
            checkCompleted: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                id: 0,
                allowMultiple: false,
                jobType: '',
                jobStatus: '',
                allServices: [],
                jobGeneralServices: [],
                serviceTypes: [],
                removalReasons: [],
                otherServiceComment: '',
                wheelLayout: [],
                selectedWheel: [],
                productMaster: [],
                selectedTyre: null,
                generalServiceId: 0,
                generalServiceIds: [],
                tempTyreServiceId: 0,
                images: [],
                index: null,
                imageEdit: false,
                productDropdownDisabled: false,
                wheelImage: null,
                checkApproval: 0,
                flattenWheelPositions: [],
                odometerReading: '',
                jobCardImage: null,
                cardImages: [],
                balanceServiceCode: [],
                actionShow: 0,
                generalServiceData: [],
                generalServQntData: [],
                mulWheelIds: [],
                options: [],
                replacement: ["S&F", "BAL", "DUMP", "TORQ"],
                repair: ["Puncture", "TORQ"],
                turnTyreOnRim: ["BAL", "TORQ"],
                jobBtn: ['Normal Callout', 'Normal Mileage', 'After Hours Callout', 'After Hours Mileage'],
                servBtn: [],
                jobCardProducts: {},
                stripFitIds: [],
                recycleIds: [],
                punctureIds: [],
                retorqueIds: [],
                rotationSrvCodes: [],
                productLoader: false,
                productInputSearch: '',
                noteAlert: true,
                totalCountCard: 3,
                mediaId: 0,
                checkPosImg: false,
                tyreOnRimServiceCode: [],
                virActions: [],
                virJobModal: false,
                modalWillOpen: true,
                wheelRiskColorCode: { 1: 'green', 2: 'orange', 3: 'red' },
            }
        },
        computed: {
            // getGeneralServices() {
            //     return this.jobGeneralServices.filter(serv => this.generalServiceIds.includes(serv.id))
            // },
            getTyreServices() {
                return this.allServices.filter(serv => this.selectedTyre.wheel_service_charges.includes(serv.id))
            },
            showProduct() {
                const found = this.serviceTypes.find(item => item.id === this.selectedTyre.service_type_ids)
                return found ? found.is_product_charge_allowed === 1 : false
            },
            showRemovalReason() {
                const found = this.serviceTypes.find(item => item.id === this.selectedTyre.service_type_ids)
                return found ? found.is_removal_reasons_allowed === 1 : false
            },
            showServiceCharge() {
                const found = this.serviceTypes.find(item => item.id === this.selectedTyre.service_type_ids)
                return found ? found.is_service_charge_allowed === 1 : false
            },
            showOldTread() {
                const found = this.serviceTypes.find(item => item.id === this.selectedTyre.service_type_ids)
                return found ? found.is_old_tread_depth_input === 1 : false
            },
            showWheelPosition() {
const found = this.serviceTypes.find(item => item.id === this.selectedTyre.service_type_ids)
                return found ? found.is_new_position_input_rotation === 1 : false
            },
            getCurrentUser() {
                return this.$store.state.auth.user;
            },
            textboxDisability() {
                if (this.getCurrentUser && this.getCurrentUser.roles[0].id === 4) {
                    if (this.jobStatus !== 'draft') {
                        return true;
                    }
                }
                return false;
            },
            wheelPositions() {
                return this.flattenWheelPositions.filter(wheel => !this.selectedWheel.includes(wheel.name))
            },
        },
        created() {
            this.id = this.$route.params.id;
            this.debounceInput = _.debounce((e) => {
                                this.productInput(e);
                            }, 500)
            this.createBlankTyreData();
            this.fetchData();
        },
        methods: {
            createBlankTyreData() {
                this.selectedTyre = {
                    id: this.id,
                    wheel_layout_ids: [],
                    allow_multiple_seleections: 0,
                    removal_reason_id: 0,
                    service_type_ids: [],
                    tread_depth: "",
                    old_tread_depth: "",
                    products: [],
                    wheel_service_charges: [],
                    new_vehicle_to_wheel_position_id: 0,
                    note: '',
                    wheel_has_issue: false,
                    vir_action_id: 0,
                }
            },
            fetchWheelSpecificData(wheelLayoutId) {
                this.$helper.showLoading();
                this.$axios.get(`/jobs/get_wheel_position`, {
                    params: {
                        id: this.id,
                        wheel_layout_id: wheelLayoutId,
                    },
                }).then(res => {
                    if (res.data.status) {
                        this.selectedTyre = { ...res.data.data, service_type_ids: res.data.data.service_type_ids.length > 0 ? res.data.data.service_type_ids[0] : 0 }
                        if (this.mulWheelIds.length > 0) {
                            this.mulWheelIds.forEach(whlId => {
                                if (this.selectedTyre.wheel_layout_ids.indexOf(whlId) === -1) {
                                    this.selectedTyre.wheel_layout_ids.push(whlId)
                                }
                            })
                        } else {
                            this.selectedTyre.wheel_layout_ids = [wheelLayoutId]
                        }
                        this.balanceServiceCode = []
                        if (res.data.data.balance_service_codes) {
                            this.balanceServiceCode = res.data.data.balance_service_codes
                        }
                        this.tyreOnRimServiceCode = res.data.data.turn_tyre_rim_service_codes ? res.data.data.turn_tyre_rim_service_codes : []
                        this.rotationSrvCodes = res.data.data.rotation_service_codes
                        // console.log('fetch balance code', this.balanceServiceCode)
                        this.images = []
                        // this.actionShow = this.selectedTyre.service_type_ids
                        this.actionShow = this.jobType === "VIR" ? this.selectedTyre.tread_depth.length : this.selectedTyre.service_type_ids
                        if (res.data.data.job_wheel_media) {
                            this.images = res.data.data.job_wheel_media
                        }
                        this.stripFitIds = res.data.data.strip_fit_service_codes
                        this.recycleIds = res.data.data.recycling_service_codes
                        this.torqueIds = res.data.data.torque_service_codes
                        this.punctureIds = res.data.data.puncture_service_codes
                    }
                })
                .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    }).finally(() => {
                        this.$helper.hideLoading();
                    });
            },
            fetchData() {
                this.$helper.showLoading();
                this.$axios.get(`/jobs/get_wheel_layout_job_card_config/${this.id}`)
                    .then(res => {
                        if (res.data.status) {
                            this.allServices = res.data.data.services_master
                            this.jobGeneralServices = res.data.data.job_general_services
                            this.serviceTypes = res.data.data.service_types_master
                            const servTypeRemoveId = this.serviceTypes.findIndex(srv => srv.name === 'Rotation')
                            if (servTypeRemoveId > -1) {
                                this.serviceTypes.splice(servTypeRemoveId, 1)
                            }
                            this.removalReasons = res.data.data.removal_reasons_master
                            this.otherServiceComment = res.data.data.other_services_comments
                            // if (this.noteAlert && this.otherServiceComment) {
                            //     this.noteAlert = false
                            //     this.$toast({
                            //         component: ToastificationContent,
                            //         props: {
                            //             title: 'A note has been entered',
                            //             icon: 'AlertCircleIcon',
                            //             variant: 'success',
                            //         },
                            //     });
                            // }
                            this.wheelLayout = res.data.data.wheel_layout
                            this.odometerReading = res.data.data.odometer_reading
                            this.jobCardProducts = res.data.data.job_card_products
                            if (this.wheelLayout.length !== 0) {
                                this.checkApproval = this.wheelLayout[0][0].id
                            }
                            this.flattenWheelPositions = []
                            this.wheelLayout.forEach(row => {
                                row.forEach(wheel => {
                                    // eslint-disable-next-line no-param-reassign
                                    wheel.saved = wheel.selected === 1;
                                    this.flattenWheelPositions.push(wheel)
                                })
                            })
                            this.flattenWheelPositions.push({ id: -1, name: 'Another Vehicle' })
                            this.generalServiceIds = res.data.data.saved_general_services.map(serviceId => serviceId.product_id)
                            this.generalServiceData = res.data.data.saved_general_services
                            // console.log('this.generalServiceIds:', res.data.data.saved_general_services)
                            this.jobType = res.data.data.job_type
                            this.jobStatus = res.data.data.job_status
                            this.cardImages = res.data.data.job_card_media
                            this.checkTorqueTyreServiceId = this.allServices.filter(item => item.code === "SVCTR")[0].id
                            this.generalServQntData = this.addQuantInGenServ()
                            // console.log('table data from fetch:', this.generalServQntData)
                            this.virActions = res.data.data.vir_actions
                        }
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                        console.error(error);
                    }).finally(() => {
                        this.$helper.hideLoading();
                    });
            },
            selectWheel(rowKey, wheelKey) {
                if (this.selectedWheel.includes(this.wheelLayout[rowKey][wheelKey].name)) {
                    this.wheelLayout[rowKey][wheelKey].selected = 0;
                    this.selectedWheel.splice(this.selectedWheel.indexOf(this.wheelLayout[rowKey][wheelKey].name), 1);
                    this.selectedTyre.wheel_layout_ids.splice(this.selectedTyre.wheel_layout_ids.indexOf(this.wheelLayout[rowKey][wheelKey].id), 1);
                    this.mulWheelIds.splice(this.mulWheelIds.indexOf(this.wheelLayout[rowKey][wheelKey].id), 1);
                } else {
                    if (this.allowMultiple) {
                        this.selectedTyre.wheel_layout_ids.push(this.wheelLayout[rowKey][wheelKey].id);
                        this.selectedWheel.push(this.wheelLayout[rowKey][wheelKey].name);
                        this.mulWheelIds = this.selectedTyre.wheel_layout_ids;
                    } else if (!this.allowMultiple) {
                        this.mulWheelIds = []
                        this.selectedTyre.wheel_layout_ids = [this.wheelLayout[rowKey][wheelKey].id]
                        this.selectedWheel = [this.wheelLayout[rowKey][wheelKey].name]
                    }
                    this.fetchWheelSpecificData(this.wheelLayout[rowKey][wheelKey].id)
                    if (this.jobType === 'VIR') {
                        // console.log('test:', this.$refs.vir_job_modal)
                        // this.$refs.vir_job_modal.show();
                        this.virJobModal = !this.virJobModal
                    }
                }
                // console.log('selected wheel: ', this.selectedWheel, this.mulWheelIds)
            },
            openGeneralServiceModal() {
                this.generalServiceId = 0;
                this.$bvModal.show('generel-services-modal');
            },
            openTyreServiceModal() {
                this.tempTyreServiceId = 0;
                this.$bvModal.show('tyre-services-modal');
            },
            openProductModal() {
                this.$bvModal.show('products-modal');
            },
            openImagesModal() {
                this.wheelImage = null;
                this.$bvModal.show('images-modal');
            },
            openJobCardImagesModal() {
                this.jobCardImage = null;
                this.$bvModal.show('job-card-images-modal');
            },
            wheelPositionDeleteConfirm() {
                this.$bvModal.show('wheel-position-delete-modal');
            },
            async addImage() {
                console.log('image: ', this.wheelImage)
                if (this.wheelImage.length > 4) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Maximum number of images you can upload at a time is 4.',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                } else {
                    const count = []
                    const info = { url: '/jobs/post_wheel_position_images', img_var: 'images', prnt_img_var: 'wheelImage' }
                    this.$helper.showLoading();
                    const params = new FormData();
                    params.append('id', this.id)
                    params.append('layout_id', this.selectedTyre.wheel_layout_ids[0])
                    this.wheelImage.forEach((img, inx) => {
                        this.callImageCompressed(params, img, inx, count, info)
                    })
                }
            },
            addProduct(value) {
                if (!this.selectedTyre.products.find(itm => itm.id === value.id)) {
                    this.selectedTyre.products.push(value)
                }
                this.options = []
                this.$bvModal.hide('products-modal');
            },
            addGeneralService() {
                // this.generalServiceIds.push(this.generalServiceId);
                // console.log(this.generalServiceId)
                if (this.generalServiceIds.indexOf(this.generalServiceId) === -1) {
                    this.generalServiceIds.push(this.generalServiceId)
                    const data = this.jobGeneralServices.filter(serv => [this.generalServiceId].includes(serv.id))
                    this.generalServQntData.push(...data.map(item => ({ ...item, quantity: 1 })))
                }
            },
            addTyreService() {
                this.selectedTyre.wheel_service_charges.push(this.tempTyreServiceId)
            },
            removeGeneralService(key) {
                this.generalServiceIds.splice(key.index, 1);
                this.generalServQntData.splice(key.index, 1);
                // console.log('remove', this.generalServQntData, key)
            },
            removeProduct(key) {
                this.selectedTyre.products.splice(key.index, 1);
            },
            removeTyreService(key) {
                this.selectedTyre.wheel_service_charges.splice(this.selectedTyre.wheel_service_charges.indexOf(key.item.id), 1);
            },
            async searchProduct(val) {
                this.productDropdownDisabled = true
                this.$axios.get(`/jobs/get_products_masterlist`, {
                    params: {
                        client_id: 1,
                        search: val,
                    },
                }).then((response) => {
                    this.productMaster = response.data;
                }).catch((err) => console.error(err)).finally(() => {
                    this.productDropdownDisabled = false
                });
            },
            setProduct(val) {
                console.log(val)
            },
            saveJobCardDetails() {
                // console.log('save table data', this.generalServQntData, this.generalServQntData.map((item) => ({ id: item.id, quantity: item.quantity })))
                const qntIds = JSON.stringify(this.generalServQntData.map((item) => ({ product_id: item.id, quantity: item.quantity })))
                this.$helper.showLoading();
                this.$axios.post('/jobs/post_save_job_card', {
                    general_service_charges: qntIds,
                    other_services_comments: this.otherServiceComment,
                    odometer_reading: this.odometerReading,
                    id: this.id,
                }).then(res => {
                    if (res.data.status) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Service Charges Added',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });
                    }
                }).catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                        console.error(error);
                    }).finally(() => {
                        this.$helper.hideLoading();
                    });
            },
            submitWheelPosition() {
                // console.log('post data', this.selectedTyre)
                if ((this.selectedTyre.service_type_ids <= 0 && this.jobType === 'FSD') || this.selectedTyre.tread_depth.length === 0) {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Please insert required field',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    return;
                }
                const products = [...this.selectedTyre.products].map(item => item.id);
                const serviceTypeIds = [this.selectedTyre.service_type_ids]
                const params = { ...this.selectedTyre, products, service_type_ids: serviceTypeIds }
                this.$helper.showLoading();
                this.$axios.post('/jobs/post_save_wheel_positions', params).then(res => {
                    if (res.data.status) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                                autoHideDelay: 15000,
                            },
                        });
                        this.fetchData();
                        this.actionShow = 1
                    }
                }).catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                        console.error(error);
                    }).finally(() => {
                        this.$helper.hideLoading();
                    });
            },
            async addCardImage() {
                if (this.jobCardImage.length > 4) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Maximum number of images you can upload at a time is 4.',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                } else {
                    const count = []
                    this.$helper.showLoading();
                    const params = new FormData();
                    params.append('id', this.id)
                    const info = { url: '/jobs/post_job_card_images', img_var: 'cardImages', prnt_img_var: 'jobCardImage' }
                    this.jobCardImage.forEach((img, inx) => {
                        this.callImageCompressed(params, img, inx, count, info)
                    })
                }
            },
            actionSelected(value) {
                this.servBtn = []
                this.balanceRemove()
                this.retorqueRemove()
                this.rotationRemove()
                this.tyreOnRimRemove()
                switch (value) {
                    case 1:
                    this.servBtn = this.replacement
                    break
                    case 2:
                    this.servBtn = this.repair
                    break
                    case 3:
                    this.selectedTyre.wheel_service_charges.push(...this.rotationSrvCodes)
                    break
                    case 6:
                    if (value === 6 && this.selectedTyre.wheel_service_charges.indexOf(this.balanceServiceCode[0]) === -1) {
                    this.selectedTyre.wheel_service_charges.push(...this.balanceServiceCode)
                    }
                    break
                    case 7:
                    if (this.selectedTyre.wheel_service_charges.indexOf(this.checkTorqueTyreServiceId) === -1) {
                    this.selectedTyre.wheel_service_charges.push(this.checkTorqueTyreServiceId)
                    }
                    break
                    case 8:
                    this.servBtn = this.turnTyreOnRim
                    if (this.tyreOnRimServiceCode.length > 0) {
                        this.selectedTyre.wheel_service_charges.push(...this.tyreOnRimServiceCode)
                    }
                    break
                    default:
                    // console.log('check action')
                }
            },
            resetFilter() {
                const params = { id: this.selectedTyre.id, wheel_layout_ids: this.selectedTyre.wheel_layout_ids }
                this.$helper.showLoading();
                this.$axios.post('/jobs/delete_saved_wheel_positions', params)
                    .then(res => {
                        if (res.data.status) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Data for wheel position removed',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            });
                            this.actionShow = 0
                            this.fetchData();
                            this.fetchWheelSpecificData(...this.selectedTyre.wheel_layout_ids);
                        }
                    }).catch(error => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Oops! Something went wrong',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                },
                            });
                            console.error(error);
                        }).finally(() => {
                            this.$helper.hideLoading();
                        });
            },
            addQuantInGenServ() {
                const addss = this.jobGeneralServices.filter(serv => this.generalServiceIds.includes(serv.id))
                const generalServiceQuantityData = []
                addss.forEach(item => {
                    const qnty = this.generalServiceData.filter(genSrv => item.id === genSrv.product_id).length === 0 ? 1 : this.generalServiceData.filter(genSrv => item.id === genSrv.product_id)[0].quantity
                    generalServiceQuantityData.push({ ...item, quantity: qnty })
                })
                // console.log('new general Service Quantity Data: ', generalServiceQuantityData, addss)
                return generalServiceQuantityData
            },
            productInput(val) {
                console.log('input ', val, val.length)
                this.productInputSearch = val
                if (val.length >= 2) {
                    this.options = []
                    this.productLoader = true
                    this.$axios.get('/jobs/get_products_masterlist', {
                        params: {
                            table: 'products',
                            column: 'description',
                            limit: 10,
                            search: val,
                            id: this.id,
                        },
                    }).then((response) => {
                        this.options = response.data.results;
                        this.disabled = false;
                        this.productLoader = false;
                    }).catch((err) => {
                        this.productLoader = false
                        console.error(err)
                    });
                } else {
                    this.options = []
                }
            },
            jobServAdd(srvData) {
                this.generalServiceId = this.jobGeneralServices.find(info => info.code === srvData).id
                this.addGeneralService()
            },
            wheelServAdd(wheelSrvData) {
                // console.log('normal check: ', wheelSrvData)
                switch (wheelSrvData) {
                    case 'S&F':
                    [this.tempTyreServiceId] = this.stripFitIds;
                    // console.log('switch: ', this.tempTyreServiceId)
                    return this.addTyreService();
                    case 'BAL':
                    [this.tempTyreServiceId] = this.balanceServiceCode;
                    return this.addTyreService();
                    case 'DUMP':
                    [this.tempTyreServiceId] = this.recycleIds;
                    return this.addTyreService();
                    case 'TORQ':
                    [this.tempTyreServiceId] = this.torqueIds;
                    return this.addTyreService();
                    case 'Puncture':
                    [this.tempTyreServiceId] = this.punctureIds
                    return this.addTyreService();
                    default:
                    this.tempTyreServiceId = 0;
                    return this.addTyreService();
                }
            },
            balanceRemove() {
                this.balanceServiceCode.map(item => item).forEach(key => {
                    if (this.selectedTyre.wheel_service_charges.indexOf(key) > -1) {
                        this.selectedTyre.wheel_service_charges.splice(this.selectedTyre.wheel_service_charges.indexOf(key), 1)
                    }
                })
            },
            tyreOnRimRemove() {
                this.tyreOnRimServiceCode.map(item => item).forEach(key => {
                    if (this.selectedTyre.wheel_service_charges.indexOf(key) > -1) {
                        this.selectedTyre.wheel_service_charges.splice(this.selectedTyre.wheel_service_charges.indexOf(key), 1)
                    }
                })
            },
            retorqueRemove() {
                if (this.selectedTyre.wheel_service_charges.indexOf(this.checkTorqueTyreServiceId) > -1) {
                    this.selectedTyre.wheel_service_charges.splice(this.selectedTyre.wheel_service_charges.indexOf(this.checkTorqueTyreServiceId), 1)
                }
            },
            rotationRemove() {
                this.rotationSrvCodes.map(item => item).forEach(key => {
                    if (this.selectedTyre.wheel_service_charges.indexOf(key) > -1) {
                        this.selectedTyre.wheel_service_charges.splice(this.selectedTyre.wheel_service_charges.indexOf(key), 1)
                    }
                })
            },
            closeProductModal() {
                this.options = []
                this.productInputSearch = ''
                console.log('hi modal')
            },
            onPageDown() {
                console.log('page down')
            },
            triggerDelete(id, check) {
            this.checkPosImg = check
            this.mediaId = id
            this.$refs.delete_modal.show();
            },
            deleteConfirm() {
                console.log('img delete', this.mediaId)
                this.$axios.post('/jobs/remove_image', {
                    id: this.mediaId,
                    type: 'job_detail',
                }).then(res => {
                    if (res.data) {
                        if (this.checkPosImg) {
                            this.images.splice(this.images.findIndex(val => val.id === this.mediaId), 1)
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Image deleted successfully.',
                                icon: 'AlertCircleIcon',
                                variant: 'Primary',
                            },
                        });
                        this.fetchData();
                    }
                }).catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                })
            },
            // prvBtn(e) {
            //     // console.log(e, e.screenY, e.screenX, e.view.pageYOffset, this.$refs.[val].offsetTop)
            //     const data = []
            //     for (let i = 1; this.totalCountCard >= i; i += 1) {
            //         let val = 'crd'
            //         val += i
            //         if (this.$refs.[val]) {
            //             data.push(this.$refs.[val].offsetTop)
            //             console.log(data)
            //         }
            //     }
            //     const top = data.filter(itm => itm < e.view.pageYOffset)
            //     const prvtop = top.length > 0 ? top[`${top.length - 1}`] : data[0]
            //     console.log(top, data, e.view.pageYOffset)
            //     window.scrollTo({
            //         top: prvtop,
            //         left: 0,
            //         behavior: 'smooth',
            //     });
            // },
            // nxtBtn(e) {
            //     // console.log(e, e.screenY, e.screenX, e.view.pageYOffset, this.$refs.[val].offsetTop)
            //     const data = []
            //     for (let i = 1; this.totalCountCard >= i; i += 1) {
            //         let val = 'crd'
            //         val += i
            //         if (this.$refs.[val]) {
            //             data.push(this.$refs.[val].offsetTop)
            //         }
            //     }
            //     const btm = data.filter(itm => itm > e.view.pageYOffset)
            //     console.log(btm, data, e.view.pageYOffset)
            //     const nxtbtm = btm.length > 0 ? btm[0] : data[`${data.length - 1}`]
            //     console.log(btm, data, e.view.pageYOffset)
            //     window.scrollTo({
            //         top: nxtbtm,
            //         left: 0,
            //         behavior: 'smooth',
            //     });
            // },
            async callImageCompressed(params, img, inx, count, info) {
                const compressed = await imageConversion.compressAccurately(img, 500)
                params.append(`image[${inx}]`, compressed);
                console.log('image1: ', img)
                count.push(inx)
                if (count.length === this.[info.prnt_img_var].length) {
                    this.addMultipleImageToServer(params, info.url, info.img_var)
                }
            },
            addMultipleImageToServer(params, url, imgVar) {
                this.$axios.post(url, params)
                    .then(resp => {
                        if (resp.status === 200) {
                            this.[imgVar].push(...resp.data.data)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Uploaded Successfully',
                                    icon: 'AlertCircleIcon',
                                    variant: 'success',
                                },
                            });
                        }
                        if (imgVar === 'cardImages') {
                            this.fetchData()
                        }
                    }).catch(err => {
                        console.error(err)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    }).finally(() => {
                        this.$helper.hideLoading();
                    })
            },
            resetModal() {
                const virModalvalidation = this.selectedTyre.tread_depth && (this.selectedTyre.wheel_has_issue ? this.selectedTyre.vir_action_id : !this.selectedTyre.wheel_has_issue)
                if (!virModalvalidation) {
                    this.virJobModal = this.modalWillOpen
                } else {
                    this.submitWheelPosition()
                }
            },
            openModal() {
                console.log('openModal')
                this.modalWillOpen = true
            },
            closeModal() {
                this.modalWillOpen = false
            },
            wheelRiskColor(wheel) {
                let inx = 0
                if (wheel.tread_depth) {
                    const treadDepth = Math.fround(wheel.tread_depth)
                    if (treadDepth <= 3) {
                        inx = 3
                    } else {
                        inx = treadDepth <= 5 ? 2 : 1
                    }
                    if (this.virActions.length > 0 && wheel.vir_action_id) {
                        const virActionInx = this.virActions.findIndex(itm => itm.id === wheel.vir_action_id)
                        if (virActionInx > -1) {
                            inx = this.virActions[virActionInx].score > inx ? this.virActions[virActionInx].score : inx
                        }
                    }
                }
                console.log('risk color', wheel)
                return this.wheelRiskColorCode[inx] ? this.wheelRiskColorCode[inx] : ''
            },
        },
    }
</script>
<style scoped>
    .min-width-5 {
        min-width: 5rem;
    }
    .min-width-6 {
        min-width: 6rem;
    }
    .width-3 {
        width: 3.2rem;
        max-height: 6rem;
        height: 6rem;
    }
    .saved::after {
        content: "\2713";
        vertical-align: middle;
        color: var(--primary);
        margin-left: 0.2rem;
    }
    .selected.saved::after {
        color: white !important;
    }
    .selected {
        background-color: #27a746 !important;
        color: white !important;
        border: none !important;
    }
    .btn-outline-primary {
        border: 2px solid #27a746 !important;
        color: #27a746;
    }
    .red.selected {
        background-color: var(--red) !important;
        color: white !important;
        border: none !important;
    }
    .red.btn-outline-primary {
        border: 2px solid var(--red) !important;
        color: var(--red);
    }
    .orange.selected {
        background-color: var(--orange) !important;
        color: white !important;
        border: none !important;
    }
    .orange.btn-outline-primary {
        border: 2px solid var(--orange) !important;
        color: var(--orange);
    }
    /*.green.selected {
        background-color: var(--green) !important;
        color: white !important;
        border: none !important;
    }
    .green.btn-outline-primary {
        border: 2px solid var(--green) !important;
        color: var(--green);
    }*/
    .text-color-primary{
        color: #27a746 !important;
    }
    .d-flex.justify-content-center {
        overflow: auto;
    }
    .btn-outline-warning {
        border: 2px solid #808080 !important;
    }
    /* .btn-outline-warning:hover {
        color: #4c4d50 !important;
    } */
    /*.btn-outline-primary:hover {
        color: var(--green) !important;
    }*/
    .list-group {
        max-height: 150px;
        overflow-y: scroll;
    }
    @media (max-width: 660px) {
        .min-width-5 {
            min-width: 2rem;
        }
        /* .min-width-6 {
            min-width: 3rem;
        } */
        .mx-2 {
            margin-left: 2px !important;
            margin-right: 2px !important;
        }
        .mx-1 {
            margin-left: 2px !important;
            margin-right: 2px !important;
        }
        .mx-1.ml-4 {
            margin-left: 1rem !important;
        }
        .d-flex.justify-content-center .d-flex.mb-1.justify-content-between {
            margin-bottom: 4px !important;
        }
        textarea#other-service-comments {
            height: 65px !important;
        }
        .card .card-header {
            padding-left: 0;
            padding-right: 0;
        }
        .card .card .card-body {
            padding: 0px;
        }
        .card .card .ml-1 {
            margin-left: 0rem !important;
        }
        .card .card .mt-2 {
            margin-top: 0.5rem !important;
        }
        .card .card {
            margin-bottom: 0rem;
        }
        /* .card .card img.card-img-top {
            max-height: 225px;
            width: auto;
        } */
    }
    .float-next {
        position:fixed;
        bottom:30px;
        right:10.5%;
        background: #27a746 !important;
        color: #ffffff !important;
    }
    .float-prv {
        position:fixed;
        bottom:30px;
        left:20%;
        background: #27a746 !important;
        color: #ffffff !important;
    }

    .btn-sm.img-close {
        position: absolute;
        z-index: 1;
        right: 0;
    }
    .btn-pos {
        text-align: center;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 1.786rem 0.5rem;
        border-radius: 0.358rem;
    }
</style>
