<template>
    <div>
        <b-modal
        id="email-send-modal"
        title="Email to Customer"
        ok-only
        no-close-on-backdrop
        >
        <b-row>
            <b-col class="col-9">
                <b-form-input
                    v-model="resEmail"
                    placeholder="Enter Email"
                />
            </b-col>
            <b-col class="col-3">
                <b-button
                @click="emailSend"
                >
                Send
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
            Shareable Url: <b-button
            class="ml-1"
            size="sm"
            variant="light"
            @click="copyUrl"
            >
            Copy
            </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                {{ `https://jobcards.tyregeneral.co.nz/job-report/${id}` }}
            </b-col>
        </b-row>
        </b-modal>
        <b-card class="mb-2">
            <b-card-header>
                <div>
                    <h4>Job Overview</h4>
                    <b-alert :show="overviewEdit" variant="secondary" dismissible>
                        All tabs are disabled, Please finish editing.
                    </b-alert>
                </div>
                <div class="float-right">
                    <!-- <b-button
                        v-show="roleIdForBlock"
                        variant="outline-warning"
                        class="mr-1"
                        @click="getDirection"
                    >
                        Get directions
                    </b-button> -->
                    <b-button
                    v-if="roleIdForBlock && checkRolePermission('jobs-sendcustemail')"
                    class="mr-1"
                    variant="outline-warning"
                    @click="openEmailSendModal"
                    >
                        Email to Customer
                    </b-button>
                <b-button
                        v-if="roleIdForBlock && checkCompleted && overviewEdit"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="overviewUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && overviewEdit"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('overviewEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="roleIdForBlock && checkCompleted"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('overviewEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Job Type
                        </b-col>
                        <b-col cols="8">
                        {{ jobType }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Docket Number
                        </b-col>
                        <b-col cols="8">
                        {{ docketNumber }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Job Date
                        </b-col>
                        <b-col cols="8">
                            <span v-if="overviewEdit">
                            <b-form-datepicker
                                id="job-datepicker"
                                v-model="jobDate"
                            />
                            <b-time
                                v-model="jobTime"
                                show-seconds
                            />
                            </span>
                            <p v-else>
                                {{ jobDate }} {{ jobTime }}
                            </p>
                        </b-col>
                    </b-row>
            </b-card-text>
        </b-card>

        <b-card
        v-if="profileId !== 4 "
        class="mb-2"
        >
            <b-card-header>
                <div>
                    <h4>Technician</h4>
                    <b-alert :show="technicianEdit" variant="secondary" dismissible>
                        All tabs are disabled, Please finish editing.
                    </b-alert>
                </div>
                <div class="float-right">
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && technicianEdit"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="technicianUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && technicianEdit"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('technicianEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="roleIdForBlock && checkCompleted"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('technicianEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Assigned To
                        </b-col>
                        <b-col cols="8">
                        <b-form-select
                            v-if="technicianEdit"
                            v-model="assignedTo"
                            :options="assignedList"
                            value-field="id"
                            text-field="name"
                        />
                        <p v-else>
                        {{ getTechnicianName }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                            Branch
                        </b-col>
                        <b-col>
                            <p>{{ technicianBranch }}</p>
                        </b-col>
                    </b-row>
                    <!--<b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Manager's Instruction
                        </b-col>
                        <b-col cols="8">
                        <b-form-textarea
                            v-if="technicianEdit"
                            v-model="managersInstruction"
                        />
                        <p v-else>
                        {{ managersInstruction }}
                        </p>
                        </b-col>
                    </b-row>-->
            </b-card-text>
        </b-card>

        <b-card class="mb-2">
            <b-card-header>
                <div>
                    <h4>Job Location</h4>
                    <b-alert
                    :show="jobLocationEdit"
                    variant="secondary" dismissible>
                        All tabs are disabled, Please finish editing.
                    </b-alert>
                </div>
                <div class="float-right">
                    <b-button
                        v-if="roleIdForBlock && jobLocationEdit"
                        :disabled="(serviceLat==='' || serviceLon==='')"
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                    >
                        <b-link
                        :href="`https://www.google.com/maps/search/${serviceLat},${serviceLon}`"
                        target="_blank"
                        :disabled="(serviceLat==='' || serviceLon==='')"
                        >
                        <feather-icon icon="MapPinIcon" />
                        </b-link>
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && jobLocationEdit"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="jobLocationUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && jobLocationEdit"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('jobLocationEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="roleIdForBlock && checkCompleted"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('jobLocationEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Location Description
                        </b-col>
                        <b-col
                        cols="8"
                        >
                        <b-form-input
                            v-if="jobLocationEdit"
                            v-model="locationForServiving"
                        />
                        <p v-else>
                        {{ locationForServiving }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        GPS Location
                        </b-col>
                        <b-col cols="8">
                        <div
                            v-if="jobLocationEdit"
                            class="d-flex align-items-center justify-content-center"
                        >
                            <b-form-input
                                v-model="serviceLat"
                                class="mr-1"
                                placeholder="Enter Latitude"
                            />
                            <b-form-input
                                v-model="serviceLon"
                                class="mr-1"
                                placeholder="Enter Longitude"
                            />
                            <div class="mr-1">
OR
</div>
                            <b-button
variant="outline-primary"
size="sm"
alt="Get Current Location"
@click="getCurrentLocation"
>
                            <feather-icon icon="MapPinIcon" />
                            </b-button>
                        </div>
                        <p v-else>
                        {{ serviceLat }}, {{ serviceLon }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Location
                        </b-col>
                        <b-col
                        cols="8"
                        >
                        {{ latlngLocation }}
                        </b-col>
                    </b-row>
            </b-card-text>
        </b-card>

        <b-card class="mb-2">
            <b-card-header>
                <div>
                    <h4>Order Details</h4>
                    <b-alert
                    :show="orderDetailsEdit"
                    variant="secondary" dismissible>
                        All tabs are disabled, Please finish editing.
                    </b-alert>
                </div>
                <div class="float-right">
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && orderDetailsEdit"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="orderDetailsUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && orderDetailsEdit"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('orderDetailsEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="roleIdForBlock && checkCompleted"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('orderDetailsEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Order Number
                        </b-col>
                        <b-col cols="8">
                            <b-form-input
                                v-if="orderDetailsEdit"
                                v-model="orderNo"
                            />
                            <p v-else>
                            {{ orderNo }}
                            </p>
                        </b-col>
                    </b-row>
                    <!--<b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Job Date
                        </b-col>
                        <b-col cols="8">
                            <b-form-datepicker
                                v-if="orderDetailsEdit"
                                id="job-datepicker"
                                v-model="jobDate"
                            />
                            <p v-else>
                                {{ jobDate }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Customer
                        </b-col>
                        <b-col cols="8">
                            {{ clientName }}
                        </b-col>
                    </b-row>-->
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Invoice Number
                        </b-col>
                        <b-col cols="8">
                            <b-form-input
                                v-if="orderDetailsEdit"
                                v-model="invoiceNo"
                            />
                            <p v-else>
                            {{ invoiceNo }}
                            </p>
                        </b-col>
                    </b-row>
            </b-card-text>
        </b-card>

        <b-card>
            <b-card-header>
                <div>
                    <h4>Contact Person</h4>
                    <b-alert
                    :show="contactPersonEdit"
                    variant="secondary" dismissible>
                        All tabs are disabled, Please finish editing.
                    </b-alert>
                </div>
                <div class="float-right">
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && contactPersonEdit"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="contactPersonUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="roleIdForBlock && checkCompleted && contactPersonEdit"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('contactPersonEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="roleIdForBlock && checkCompleted"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('contactPersonEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Contact Name
                        </b-col>
                        <b-col cols="8">
                            <b-form-input
                                v-if="contactPersonEdit"
                                v-model="contactName"
                            />
                            <p v-else>
                            {{ contactName }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                            Contact Phone Number
                        </b-col>
                        <b-col cols="8">
                            <b-form-input
                                v-if="contactPersonEdit"
                                v-model="contactPh"
                            />
                            <p v-else>
                            {{ contactPh }}
                            </p>
                        </b-col>
                    </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
import axios from 'axios'
import {
 BCard, BCardText, BCardHeader, BButton, BRow, BCol, BFormInput, BFormDatepicker, BTime, BFormSelect, BLink, BAlert,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
    components: {
 BCard, BCardText, BCardHeader, BButton, BRow, BCol, BFormInput, BFormSelect, BFormDatepicker, BTime, BLink, BAlert,
},
    props: {
        jobId: {
            type: String,
            required: true,
        },
        profileId: {
            type: Number,
            default: 0,
        },
        roleIdForBlock: {
            type: Boolean,
            required: true,
            default: true,
        },
        roleIdPriority: {
            type: Boolean,
            required: true,
            default: true,
        },
        checkCompleted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: 0,
            docketNumber: '',
            job_type: '',
            technicianEdit: false,
            jobLocationEdit: false,
            orderDetailsEdit: false,
            contactPersonEdit: false,
            assignedTo: 0,
            assignedList: [],
            assignedToName: '',
            managersInstruction: '',
            locationForServiving: '',
            serviceLat: '',
            serviceLon: '',
            gpsLocation: '',
            orderNo: 0,
            jobDate: null,
            jobTime: null,
            /* clientName: '', */
            clientId: 0,
            invoiceNo: 'Not generated yet',
            contactName: '',
            contactPh: '',
            methodName: '',
            jobType: '',
            overviewEdit: false,
            latlngLocation: '',
            callApiOrNot: false,
            resEmail: '',
            technicianBranch: '',
        };
    },
    computed: {
        getTechnicianName() {
            const found = this.assignedList.find(tech => tech.id === this.assignedTo);
            return found ? found.name : '';
        },
    },
    created() {
        this.id = this.$route.params.id;
        console.log('data is', this.roleIdForBlock)
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$helper.showLoading();
            this.$axios.get(`/jobs/get_job_overview/${this.id}`)
                .then(res => {
                    this.$helper.hideLoading();
                    // console.log(res);
                    if (res.data) {
                        this.jobType = res.data.job_type;
                        this.docketNumber = res.data.docket_number;
                        this.assignedToName = res.data.settings.technician_name;
                        this.assignedList = res.data.settings.technician_list;
                        this.managersInstruction = res.data.manager_instructions;
                        this.locationForServiving = res.data.location_of_servicing;
                        this.serviceLat = res.data.latitude;
                        this.serviceLon = res.data.longitude;
                        this.orderNo = res.data.order_number;
                        [this.jobDate, this.jobTime] = res.data.job_date.split(" ");
                        // this.clientName = res.data.client;
                        this.clientId = res.data.client_id;
                        this.invoiceNo = res.data.invoice_number;
                        this.contactName = res.data.contact_name;
                        this.contactPh = res.data.contact_phone_number;
                        this.assignedTo = res.data.assigned_to;
                        if (this.callApiOrNot || this.latlngLocation === '') {
                            this.callApiOrNot = false
                            this.getAddress(this.serviceLat, this.serviceLon)
                        }
                        this.technicianBranch = res.data.technician_branch.length > 0 ? res.data.technician_branch.toString().replaceAll(',', ', ') : ''
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        callServer(data, method) {
            const params = { ...data };
            // console.log(params);
            this.$helper.showLoading();
            this.$axios.post(`/jobs/${method}/${this.id}`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Job Updated',
                                icon: 'CheckIcon',
                                variant: 'Primary',
                            },
                        });
                        this.editView = false;
                        this.$emit('enable-tabs', false);
                        this.fetchData()
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        showValidationToast() {
            this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please fill all the details first',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
        },
        contactPersonUpdate() {
            if (this.contactName.trim().length > 0 && this.contactPh.trim().length > 0) {
                this.contactPersonEdit = false;
                this.callServer({
                    contact_name: this.contactName,
                    phone_number: this.contactPh,
                }, "edit_contact");
            } else {
                this.showValidationToast();
            }
        },
        orderDetailsUpdate() {
                this.orderDetailsEdit = false;
                this.callServer({
                    // client_id: this.clientId,
                    order_number: this.orderNo,
                    // job_date: this.jobDate,
                    // invoice_number: this.invoiceNo,
                }, "edit_order");
        },
        jobLocationUpdate() {
            if (this.serviceLat.trim().length > 0 && this.locationForServiving.trim().length > 0 && this.serviceLon.trim().length > 0) {
                this.jobLocationEdit = false;
                this.callApiOrNot = true;
                this.callServer({
                    latitude: this.serviceLat,
                    longitude: this.serviceLon,
                    landmark: this.locationForServiving,
                }, "edit_location");
            } else {
                this.showValidationToast();
            }
        },
        technicianUpdate() {
            if (this.assignedTo > 0) {
                this.technicianEdit = false;
                this.methodName = "edit_technician";
                this.callServer({
                    user_id: this.assignedTo,
                    manager_instruction: this.managersInstruction,
                }, "edit_technician");
            } else {
                this.showValidationToast();
            }
        },
        overviewUpdate() {
            if (this.jobDate.trim().length > 0) {
                console.log('time', this.jobTime)
                this.overviewEdit = false;
                this.callServer({
                    job_date: this.jobDate.concat(' ', this.jobTime),
                }, "edit_overview");
            } else {
                this.showValidationToast();
            }
        },
        getCurrentLocation() {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log(position.coords)
                this.serviceLat = position.coords.latitude.toString()
                this.serviceLon = position.coords.longitude.toString()
            }, () => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please allow access to the browser location',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
            });
        },
        getDirection() {
            if (this.serviceLat.trim().length === 0 || this.serviceLon.trim().length === 0) {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please enter service location first',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                return;
            }
            navigator.geolocation.getCurrentPosition((position) => {
                window.open(`http://maps.google.com/maps?saddr=${position.coords.latitude},${position.coords.longitude}&daddr=${this.serviceLat},${this.serviceLon}`, '_BLANK')
            }, () => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please allow access to the browser location',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
            });
        },
        async getAddress(lat, long) {
            try {
                const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBXcpLBxfEnWONKrLQBNsA13DWLlQUMl_E`);
                if (data.results[0].formatted_address.trim().length > 0) {
                    this.latlngLocation = data.results[0].formatted_address;
                }
            } catch (error) {
                console.log(error.message);
            }
        },
        openEmailSendModal() {
            this.$bvModal.show('email-send-modal')
        },
        emailSend() {
            this.$axios.post('/jobs/job_email', { email: this.resEmail, job_id: this.id })
                .then(res => {
                    if (res.data) {
                        console.log('email service call: ', res.data)
                        this.resEmail = ''
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.log(err)
                })
        },
        copyUrl() {
            // console.log(process.env.VUE_APP_API_URL)
            navigator.clipboard.writeText(`https://jobcards.tyregeneral.co.nz/job-report/${this.id}`);
        },
        passStatus(data, val) {
            this.[data] = val
            if (!val && !(this.overviewEdit || this.technicianEdit || this.jobLocationEdit || this.orderDetailsEdit || this.contactPersonEdit)) {
                this.$emit('tab-edit-status', val);
            } else if (val) {
                this.$emit('tab-edit-status', val);
            }
        },
        checkRolePermission(val) {
            return this.$store.state.auth.user.permissions.indexOf(val) > -1
        },
    },
}
</script>
<style scoped>
    .row {
        margin-bottom: 0.5rem;
    }
    .feather-icon-style {
        color: #fff;
    }
    .feather-icon-btn {
        border: 1px solid #fff !important;
        background-color: #008000 !important;
    }
    .alert.alert-dismissible.alert-secondary {
        position: relative;
        padding: 0.75rem 4rem 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
</style>
