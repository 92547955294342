<template>
    <div>
        <b-modal
            id="delete_modal"
            ref="delete_modal"
            title="Delete Confirmation"
            @ok="deleteConfirm"
        >
            <p class="my-4">
                Are you sure you want to delete?
            </p>
        </b-modal>
        <b-card class="mb-2">
            <b-card-header>
                <div>
                <h4>Vehicle Detail</h4>
                <b-alert :show="vehicleDetailEdit" variant="secondary" dismissible>
                    All tabs are disabled, Please finish editing.
                </b-alert>
                </div>
                <div class="float-right">
                    <b-button
                        v-if="vehicleDetailEdit && checkCompleted"
                        v-show="roleIdForBlock"
                        class="feather-icon-btn mr-1"
                        size="sm"
                        variant="outline-primary"
                        @click="vehicleDetailUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="vehicleDetailEdit && checkCompleted"
                        v-show="roleIdForBlock"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('vehicleDetailEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="checkCompleted"
                        v-show="roleIdForBlock"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('vehicleDetailEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </div>
            </b-card-header>
            <b-card-text class="px-2">
            <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Equipment Class
                        </b-col>
                        <b-col cols="8">
                            <!--<custom-autosuggest
                                v-if="vehicleDetailEdit"
                                table="equipment_classes"
                                label="name"
                                limit="30"
                                :value="equipmentClassId"
                                @changed="equipmentClassSelected"
                            />-->
                            <b-form-group
                                v-if="vehicleDetailEdit"
                                :state="equipmentClass.length > 0 ? null:false"
                            >
                            <v-select
                                id="equipmentClassId"
                                label="name"
                                :value="equipmentClass"
                                :options="equipmentOption"
                                @input="equipmentClassSelected"
                            />
                            </b-form-group>
                            <p v-else>
                                {{ equipmentClass }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Vehicle Layout
                        </b-col>
                        <b-col cols="8">
                            <!--<custom-autosuggest
                                v-if="vehicleDetailEdit"
                                table="wheel_layouts"
                                label="name"
                                limit="30"
                                :value="vehicleLayoutId"
                                @changed="vehicleLayoutSelected"
                            />-->
                            <b-form-group
                                v-if="vehicleDetailEdit"
                                :state="vehicleLayout.length > 0 ? null:false"
                            >
                            <v-select
                            label="name"
                            :disabled="showVehicleLayout"
                            :options="vehicleLayoutOption"
                            :reduce="item => item.id"
                            :value="vehicleLayout"
                            @option:selected="vehicleOptionChosen"
                            />
                            </b-form-group>
                            <p v-else>
                                {{ vehicleLayout }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                        Branch
                        </b-col>
                        <b-col cols="8">
                        <custom-autopopulate
                            v-if="vehicleDetailEdit"
                            id="branchId"
                            module="branches"
                            retrieve="full_object"
                            label="name"
                            :jobBranchValidation="branchId > 0 ? null:false"
                            :value="branchId"
                            @changed="branchSelected"
                        />
                        <p v-else>
                            {{ branchName }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                        Customer
                        </b-col>
                        <b-col cols="8">
                        <div
                            v-if="vehicleDetailEdit"
                        >
                            <b-form-checkbox
                            id="CheckId"
                            v-model="clientCheckId"
                            name="CheckName"
                            value=""
                            unchecked-value="1"
                            >
                            I don't know
                            </b-form-checkbox>
                            <!--<custom-autosuggest
                                v-if="vehicleDetailEdit && clientCheckId==1"
                                table="clients"
                                label="client_name"
                                :value="clientId"
                                @changed="clientSelected"
                            />-->
                            <b-form-group
                                v-if="vehicleDetailEdit"
                                :state="clientName.length > 0 ? null:false"
                            >
                            <v-select
                            v-show="clientCheckId==1"
                            label="client_name"
                            :disabled="showClient"
                            :options="clientVal"
                            :value="clientName"
                            @search="userInput"
                            @option:selected="optionChosen"
                            >
                            <slot
                            v-if="selectedInput.length>3"
                            name="no-options"
                            >
                            Sorry, no matching options.
                            </slot>
                            <div
                            v-else
                            slot="no-options"
                            >
                            Enter at least 4 characters.
                            </div>
                            </v-select>
                            </b-form-group>
                        </div>
                        <p v-else>
                            {{ clientName }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="4"
                            class="font-weight-bold"
                        >
                        Fleet #
                        </b-col>
                        <b-col cols="8">
                        <b-form-input
                            v-if="vehicleDetailEdit"
                            v-model="fleetNumber"
                        />
                        <p v-else>
                            {{ fleetNumber }}
                        </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Registration Number
                        </b-col>
                        <b-col
                        v-if="vehicleDetailEdit"
                        cols="8"
                        >
                        <b-form-input
                            v-model="registrationNumber"
                            :state="notRoadRegistered>0 ? null : registrationNumber.length<7 && registrationNumber.length>0"
                        />
                        <b-form-invalid-feedback id="input-live-feedback">
                        Please insert valid Registration Number
                        </b-form-invalid-feedback>
                        </b-col>
                        <b-col
                        v-else
                        cols="8"
                        >
                        <p>
                        {{ registrationNumber }}
                        </p>
                        </b-col>
                    </b-row>
                    <!--<b-row>
                        <b-col
                        cols="4"
                        class="font-weight-bold"
                        >
                        Odometer Reading
                        </b-col>
                        <b-col cols="8">
                        <b-form-input
                            v-if="vehicleDetailEdit"
                            v-model="odometerNo"
                        />
                        <p v-else>
                        {{ odometerNo }}
                        </p>
                        </b-col>
                    </b-row>-->
            </b-card-text>
        </b-card>
        <b-card class="mb-1">
        <b-card-header>
            <div>
                <h3>Vehicle Photos</h3>
                <b-alert :show="imageEdit" variant="secondary" dismissible>
                    All tabs are disabled, Please finish editing.
                </b-alert>
            </div>
            <span class="float-right">
                <b-button
                        v-if="imageEdit && checkCompleted"
                        v-show="roleIdForBlock"
                        class="feather-icon-btn"
                        size="sm"
                        variant="outline-primary"
                        @click="imageUpdate"
                    >
                        <feather-icon class="feather-icon-style" icon="CheckIcon" />
                    </b-button>
                    <b-button
                        v-if="imageEdit && checkCompleted"
                        v-show="roleIdForBlock"
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('imageEdit', false)"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                        v-else-if="checkCompleted"
                        v-show="roleIdForBlock"
                        size="sm"
                        variant="outline-primary"
                        @click="passStatus('imageEdit', true)"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>
            </span>
        </b-card-header>
        <b-card-text>
            <div class="container mt-2 ml-1">
            <CoolLightBox
            :items="imageLink.map(item => item.path)"
            :index="index"
            @close="index = null"
            />
                <div
                    v-if="!imageEdit"
                    class="carousel_block"
                    >
                    <carousel
                        ref="carousel"
                        :per-page="3"
                        :pagination-enabled="false"
                        :navigation-enabled="true"
                        :navigation-click-target-size="4"
                        :loop="true"
                        :per-page-custom="[
                            [320, 1],
                            [768, 1],
                            [1199, 3],
                        ]"
                    >
                        <slide
                            v-for="(imgLink, imgIndex) in imageLink"
                            :key="imgIndex"
                        >
                            <div
                                class="card m-2"
                                style="cursor: pointer;"
                            >
                                <b-button
                                    size="sm"
                                    variant="danger"
                                    class="img-close"
                                    @click="triggerDelete(imgLink.media_id)"
                                >X
                                </b-button>
                                <img
                                    :src="imgLink.path"
                                    alt=""
                                    class="card-img-top"
                                    @click="index = imgIndex"
                                >
                            </div>
                        </slide>
                    </carousel>
                </div>
                <b-form-file
                    v-else
                    v-model="image"
                    :state="Boolean(image)"
                    placeholder="Choose a file"
                    accept="image/*"
                />
            </div>
        </b-card-text>
    </b-card>
    </div>
</template>
<script>
import * as imageConversion from 'image-conversion';
import vSelect from 'vue-select';
import {
 BCard, BCardText, BCardHeader, BRow, BCol, BButton, BFormInput, BFormFile, BFormCheckbox, BFormInvalidFeedback, BFormGroup, BAlert,
} from "bootstrap-vue";
import { Carousel, Slide } from 'vue-carousel';
import CoolLightBox from 'vue-cool-lightbox';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CustomAutosuggest from '../../common/components/CustomAutosuggest.vue';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import CustomAutopopulate from '../../common/components/CustomAutopopulate';
export default {
    components: {
        BCard, BCardText, BCardHeader, BRow, BCol, BButton, BFormInput, Carousel, Slide, CoolLightBox, BFormFile, BFormCheckbox, BFormInvalidFeedback, CustomAutopopulate, vSelect, BFormGroup, BAlert,
    },
    props: {
        roleIdForBlock: {
            type: Boolean,
            required: true,
            default: true,
        },
        checkCompleted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: 0,
            vehicleDetailEdit: false,
            clientName: '',
            clientId: 0,
            vehicleLayoutId: 0,
            // odometerNo: '',
            fleetNumber: '',
            vehicleLayout: '',
            registrationNumber: '',
            imageLink: [],
            imageEdit: false,
            index: null,
            image: null,
            clientCheckId: 1,
            equipmentClassId: 0,
            equipmentClass: '',
            branchId: 0,
            showClient: true,
            clientVal: [],
            branchName: '',
            vehicleLayoutOption: [],
            showVehicleLayout: true,
            selectedInput: '',
            equipmentOption: [],
            selectedEquipmentClass: '',
            selectedVehicleLayout: '',
            notRoadRegistered: 0,
            mediaId: 0,
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    methods: {
        vehicleDetailUpdate() {
            if (this.clientCheckId === "") {
                this.clientId = -1;
            }
            const regValidation = this.notRoadRegistered > 0 ? true : this.registrationNumber.trim().length < 7 && this.registrationNumber.trim().length > 0
            if (this.clientId !== 0 && this.vehicleLayoutId !== 0 && regValidation) {
                this.vehicleDetailEdit = false;
                this.callServer({
                    type: 'detail',
                    client_id: this.clientId,
                    fleet_number: this.fleetNumber,
                    wheel_layout_id: this.vehicleLayoutId,
                    registration_number: this.registrationNumber,
                    id: this.id,
                }, 'edit_detail');
            } else {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please fill all details',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
            }
        },
        callServer(data, method) {
            // const params = { ...data };
            // console.log(params);
            this.$helper.showLoading();
            this.$axios.post(`/jobs/${method}`, data)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Vehicle Details Updated',
                                icon: 'CheckIcon',
                                variant: 'Primary',
                            },
                        });
                        this.$emit('enable-tabs', false);
                        this.fetchData();
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        async imageUpdate() {
            const fd = new FormData();
            const compressed = await imageConversion.compressAccurately(this.image, 500)
            console.log(`${this.image.size}B compressed to ${compressed.size}B`)
            this.imageEdit = false;
            fd.append('image', compressed);
            fd.append('id', this.id)
            this.callServer(fd, "edit_image");
        },
        fetchData() {
            this.$helper.showLoading();
            this.$axios.get(`/jobs/get_job_detail/${this.id}`)
                .then(res => {
                    this.$helper.hideLoading();
                    // console.log(res);
                    if (res.data) {
                        this.clientName = res.data.client
                        // this.odometerNo = res.data.odometer_reading
                        this.fleetNumber = res.data.fleet
                        this.registrationNumber = res.data.registration_number
                        this.clientId = res.data.client_id
                        this.equipmentOption = res.data.equipement_class
                        this.notRoadRegistered = res.data.is_not_road_registered
                        console.log('notRoadRegistered', this.notRoadRegistered)
                        if (res.data.equipment_class_selected) {
                            this.equipmentClass = res.data.equipment_class_selected.name
                            this.equipmentClassId = res.data.equipment_class_selected.id
                            this.equipmentClassSelected(res.data.equipment_class_selected)
                        }
                        this.vehicleLayout = res.data.vehicle_layout
                        if (res.data.home_branch.id) {
                            this.branchId = res.data.home_branch.id
                            this.branchName = res.data.home_branch.name
                            this.showClient = false;
                        } else {
                            this.branchId = res.data.branch_selected.id
                            this.branchName = res.data.branch_selected.name
                        }
                        if (this.clientId === '') {
                            this.clientCheckId = '';
                        }
                        this.vehicleLayoutId = res.data.vehicle_layout_id
                        this.imageLink = res.data.image
                        const regValidation = this.notRoadRegistered > 0 ? true : this.registrationNumber.trim().length > 0
                        if (this.clientId === 0 && this.vehicleLayoutId.trim().length === 0 && regValidation) {
                            this.vehicleDetailEdit = true;
                        }
                    }
                    // this.getData();
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        clientSelected(val) {
            if (val) {
                this.clientName = val.client_name;
                this.clientId = val.id;
            }
        },
        vehicleLayoutSelected(val) {
            if (val) {
                this.vehicleLayout = val.name;
                this.vehicleLayoutId = val.id;
            }
        },
        equipmentClassSelected(val) {
            if (this.vehicleLayout !== '') {
                this.selectedVehicleLayout = this.vehicleLayout;
                this.selectedEquipmentClass = this.equipmentClass
                this.vehicleLayout = '';
            }
            if (val) {
                if (this.vehicleLayout === '' && this.selectedEquipmentClass === val.name) {
                    this.vehicleLayout = this.selectedVehicleLayout;
                }
                this.equipmentClass = val.name;
                this.equipmentClassId = val.id;
                this.fetchSelected({
                    equipment_class_id: this.equipmentClassId,
                }, '/jobs/getVehicleLayout');
            }
        },
        branchSelected(value) {
            if (value) {
                this.clientVal = [];
                this.clientName = '';
                this.branchId = value.id;
                this.showClient = false;
            }
        },
        userInput(item) {
            if (window.innerWidth <= 660) {
                window.scrollTo({
                    top: 425,
                    behavior: 'smooth',
                })
            }
            console.log('changes customer value: ', this.clientVal)
            this.selectedInput = item
            console.log('check selected client input:', this.selectedInput)
            if (item.length > 3) {
                this.fetchSelected({
                    search: item,
                    branch_id: this.branchId,
                }, '/vehicles/getClientData');
            }
        },
        fetchSelected(data, url) {
            console.log(url)
            const params = { ...data }
            this.$helper.showLoading()
            this.$axios.post(url, params)
                .then(res => {
                    this.$helper.hideLoading()
                    if (res.data && url === '/vehicles/getClientData') {
                        this.clientVal = res.data
                        this.showClient = false
                        console.log('customer clientVal post: ', this.clientVal)
                    } else if (res.data && url === '/jobs/getVehicleLayout') {
                        this.vehicleLayoutOption = res.data
                        this.showVehicleLayout = false
                        console.log('customer vehicleLayout post: ', this.vehicleLayoutOption)
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                })
        },
        optionChosen(val) {
            if (val) {
                this.clientId = val.id
                this.clientName = val.client_name
            }
        },
        vehicleOptionChosen(val) {
            if (val) {
                this.vehicleLayoutId = val.id;
                this.vehicleLayout = val.name;
            }
        },
        passStatus(data, val) {
            this.[data] = val
            if (!val && !(this.imageEdit || this.vehicleDetailEdit)) {
                this.$emit('tab-edit-status', val);
            } else if (val) {
                this.$emit('tab-edit-status', val);
            }
        },
        triggerDelete(id) {
            this.mediaId = id
            this.$refs.delete_modal.show();
        },
        deleteConfirm() {
            console.log('img delete', this.mediaId)
            this.$axios.post('/jobs/remove_image', {
                id: this.mediaId,
            }).then(res => {
                if (res.data) {
                    console.log('Image deletion done', res.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Image deleted successfully.',
                            icon: 'AlertCircleIcon',
                            variant: 'Primary',
                        },
                    });
                    this.fetchData();
                }
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops! Something went wrong',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
            })
        },
    },
}
</script>
<style scoped>
    .row {
        margin-bottom: 0.5rem;
    }
    .feather-icon-style {
        color: #fff;
    }
    .feather-icon-btn {
        border: 1px solid #fff !important;
        background-color: #008000 !important;
    }
    .feather-icon-btn:not(:disabled):not(.disabled):focus,
    .feather-icon-btn:hover:not(.disabled):not(:disabled) {
        background-color: #008000 !important;
    }
    .alert.alert-dismissible.alert-secondary {
        position: relative;
        padding: 0.75rem 4rem 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
    .btn-sm.img-close {
        position: absolute;
        z-index: 1;
        right: 0;
    }
</style>
